import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
// import { initReactI18next } from 'react-i18next/hooks'

import { en } from '../lang'

i18n
//   .use(LanguageDetector)
  // .use(initReactI18next)
  .init({
    resources: {
      en
    },
    interpolation: {
      escapeValue: false
    },
    debug: false,
    fallbackLng: 'no',
    react: {
      wait: true
    }
  })

export default i18n