import React from 'react';
import PropTypes from 'prop-types';

class IonIcon extends React.Component {
  render() {
    return <ion-icon name={this.props.iName} size={this.props.iSize}></ion-icon>;
  }
}

IonIcon.propTypes = {
  iName: PropTypes.string.isRequired,
  iSize: PropTypes.string,
};

export default IonIcon;