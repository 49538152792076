import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Project from './Project';
import { Content } from '../../components';

class Projects extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/projects">
          <Content title="Projects">
            <ul>
              <li>
                <Link to={`/projects/f219f22e-8c11-42c0-a748-6389fd081b99`}>
                  Project Master
                </Link>
              </li>
              <li>
                <Link to={`/projects/next`}>
                  Project Next
                </Link>
              </li>
            </ul>
          </Content>
        </Route>
        <Route exact path="/projects/next">
          <Project />
        </Route>
        <Route path="/projects/:projectId">
          <Project />
        </Route>
      </Switch>
    );
  }
}

export default Projects;