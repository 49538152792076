import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import Projects from './Projects';
import { Page } from '../components';

class Views extends React.Component {
  render() {
    return (
      <Router>
        <Page>
          <Switch>
            <Route path='/' exact>
              <Dashboard />
            </Route>
            <Route path='/projects' exact>
              <Projects />
            </Route>
            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
        </Page>
      </Router>
    );
  }
}

export default Views;