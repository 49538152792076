class Storage {

  constructor() {
    window['4ud17x355'] = window['4ud17x355'] || {};
  }

  clear() {
    this.memoryCache = {};
    window.localStorage.clear();
  }

  decode(str) {
    return window.atob(str);
  }

  encode(str) {
    return window.btoa(str);
  }

  get(key, defaultValue) {
    if (this.memoryCache.hasOwnProperty(key)) {
      return this.memoryCache[key];
    } else {
      const value = window.localStorage.getItem(key);
      if (Boolean(value)) {
        try {
          return this.memoryCache[key] = window.JSON.parse(value);
        } catch {
          return this.memoryCache[key] = value;
        }
      } else {
        return defaultValue;
      }
    }
  }

  getAsync(key) {
    return new Promise((resolve, reject) => {
      const value = this.get(key);
      if (Boolean(value)) {
        resolve(value);
      } else {
        reject(new Error(`Store: '${key}' not found`));
      }
    });
  }

  get memoryCache() {
    return window['4ud17x355'];
  }

  remove(key) {
    if (this.memoryCache.hasOwnProperty(key)) {
      delete this.memoryCache[key];
    }
    window.localStorage.removeItem(key);
  }

  set(key, value) {
    this.memoryCache[key] = value;
    if (typeof value === 'object') {
      window.localStorage.setItem(key, window.JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, value);
    }
  }

}

export default Storage;