import React from 'react';
import { Storage } from '../services';

function withStorage(Component) {
  return class extends React.Component {

    storage = new Storage();
    
    render() {
      return <Component {...this.props}/>
    }
  };
}

export default withStorage;
