import React from 'react';
import { Ref, Sidebar } from 'semantic-ui-react';
import SideBar from './Sidebar';
import withStorage from './withStorage';

const sidebarRef = React.createRef();

class Page extends React.Component {
  render() {
    return (
      <Sidebar.Pushable className="full-height">
        <SideBar target={sidebarRef} />
        <Ref innerRef={sidebarRef}>
          <Sidebar.Pusher dimmed={false}>
            {this.props.children}
          </Sidebar.Pusher>
        </Ref>
      </Sidebar.Pushable>
    );
  }
}

export default withStorage(Page);