import React from 'react';
import { Content } from '../components';

class NotFound extends React.Component {
  render() {
    return (
      <Content title="Not Found">
        <p>Sorry, we cannot find what you're looking for.</p>
      </Content>
    );
  }
}

export default NotFound;