import React from 'react';
import { Content } from '../../components';

class Project extends React.Component {
  render() {
    console.debug(this.props);
    return (
      <Content title="Project">
        
      </Content>
    );
  }
}

export default Project;