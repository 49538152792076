import React from 'react';
import { Container, Header } from 'semantic-ui-react';

class Content extends React.Component {
  render() {
    return (
      <Container>
        {this.props.title && <Header as='h2'>{this.props.title}</Header>}
        {this.props.children}
      </Container>
    );
  }
}

export default Content;