import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import process from 'process';
import IonIcon from './IonIcon';
import withStorage from './withStorage';

class SideBar extends React.Component {
  render() {
    return (
      <Sidebar
        as={Menu}
        animation="push"
        borderless={false}
        target={this.props.target}
        vertical
        visible
      >
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="speedometer-outline" iSize="large"></Icon>Dashboard
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="chatbubbles-outline"></Icon>Inbox
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="people-outline"></Icon>Clients
        </Menu.Item>
        <Menu.Item as={Link} to="/projects">
          <Icon as={IonIcon} iName="journal-outline"></Icon>Projects
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="chatbox-outline"></Icon>Communication
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="briefcase-outline"></Icon>Accountants
        </Menu.Item>
        {
          process.env.REACT_APP_AX_ROLE === 'Super' &&
          <Menu.Item as={Link} to="/">
            <Icon as={IonIcon} iName="business-outline"></Icon>Companies
          </Menu.Item>
        }
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="person-outline"></Icon>Users
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="shield-outline"></Icon>Roles & Permissions
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon as={IonIcon} iName="settings-outline"></Icon>Settings
        </Menu.Item>
      </Sidebar>
    );
  }
}

Sidebar.propTypes = {
  target: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLElement),
  }).isRequired,
};

export default withStorage(SideBar);